import { getAxiosInstance } from '@/api';
import { hasErrorResponse, UnauthorizedError } from './errors.js';


const mapServerSideAccessToken = (source) => ({
  tokenType: source.token_type,
  expiresIn: source.expires_in,
  accessToken: source.access_token,
  refreshToken: source.refresh_token
});

export const issueRefreshTokenGrantAccessToken = async (req) => {
  try {
    const { data } = await getAxiosInstance().post('oauth/token', {
      grant_type: 'refresh_token',
      refresh_token: req.refreshToken,
      client_id: import.meta.env.VITE_API_PERSONAL_CLIENT_ID,
      client_secret: import.meta.env.VITE_API_PERSONAL_CLIENT_SECRET
    });

    return mapServerSideAccessToken(data);
  } catch (err) {
    if (!hasErrorResponse(err)) {
      throw err;
    }

    const { data: response } = err.response;

    if (response.error) {
      throw new UnauthorizedError(response.hint ?? response.message);
    }

    throw err;
  }
};
