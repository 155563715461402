<template>
  <UiDialog v-if="showDialog" width="420px" v-model="showDialog" @update:modelValue="emit('update:modelValue', false)">
    <div class="config">
      <div class="config__header">
        <ModalHeader
            :title="$t('config.title')"
            :is-title-mini="true"
            @close="$emit('update:modelValue', false)"
        />
        <div class="divider"/>
      </div>
      <div class="config__body">
        <UiSelect
            v-model="timezone"
            :label="$t('config.label')"
            :placeholder="$t('config.placeholder')"
            :options="allTimezones"
            height="48px"
            width="100%"
            truckBy="value"
            @change=""
        />
        <p @click="useCurrentTimezone" class="config__body-current">{{$t('config.current')}}</p>
      </div>
      <div class="divider"/>
      <div class="config__footer">
        <UiButton @click="saveTimezoneHandler" primary width="100%" height="36px">
          <p class="config__footer-button">{{$t('config.save')}}</p>
        </UiButton>
      </div>
      </div>
  </UiDialog>
</template>

<script setup>
import { defineEmits, defineProps, toRef, ref } from "vue";

import UiDialog from '@/components/ui/dialog.vue';
import ModalHeader from '@/components/modals/components/header.vue';
import UiSelect from '@/components/ui/select.vue';
import UiButton from '@/components/ui/button.vue';
import UiTooltip from '@/components/ui/tooltip.vue'

import { allTimezones, getTimezone } from "@/utils/helpers/timezone.js";
import { userStore } from "@/store/modules/user.js";
import { useI18n } from 'vue-i18n';

import axiosInstance from '@/utils/axios';
import requestLogger from '@/utils/requestLogger';

const UserStore = userStore();
const { t } = useI18n();

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
});
const emit = defineEmits(['update:modelValue']);

const showDialog = toRef(props, 'modelValue');
const timezone = ref(null)

const useCurrentTimezone = ()=>{
  const peace = getTimezone()
  timezone.value = { value: peace, label: peace }
}

const saveTimezoneHandler = ()=>{
  UserStore.SET_TIMEZONE_KEY(timezone.value.value)
  emit('update:modelValue', false);
  requestLogger.retryRequests(axiosInstance);
}
</script>

<style lang="scss" scoped>
.config {
  padding: 18px 0 0;
  height: 100%;
  max-height: 235px;
  background: #FFF;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.06), 0 8px 40px rgba(0, 0, 0, 0.04);
  box-sizing: border-box;

  &__header {
    padding: 0 20px;
    box-sizing: border-box;
  }

  &__body {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    padding: 16px 20px;

    &-current{
      cursor: pointer;
      margin-left: 10px;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.4px;
      text-align: left;
      color: #3A7CCA;
    }
  }

  &__footer{
    padding: 12px 20px 20px;

    &-button{
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
      text-align: left;
    }
  }

  .divider {
    margin-left: -20px;
    width: calc(100% + 40px);
    height: 1px;
    background: #CDD4DD;
  }
}
</style>
