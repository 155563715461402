import axios from 'axios';
import { API_URL } from '@/common/config';
import JwtService from '../common/jwt.service.js';
import { useToast } from 'vue-toast-notification';
import { userStore } from "@/store/modules/user.js";
import requestLogger from '@/utils/requestLogger';

const $toast = useToast();

export class ValidationError extends Error {
    constructor(response) {
        super();
        this.errors = response.data.errors;
    }

    hasError(key) {
        return !!this.errors[key];
    }

    getError(key) {
        return this.hasError(key) ? this.errors[key][0] : undefined;
    }

    getErrors() {
        return Object.keys(this.errors).reduce(
            (agg, key) => ({ ...agg, [key]: this.errors[key] }),
            {}
        );
    }
}

const apiToken = JwtService.getToken();

const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: apiToken ? `Bearer ${apiToken}` : null
    }
});

axiosInstance.interceptors.request.use(
    (config) => {
        const UserStore = userStore();
        const timezone = UserStore.getTimezoneKey;
        if (timezone) {
            if (['post', 'put', 'get', 'delete', 'patch'].includes(config.method)) {
                config.data.timezone_key =  timezone
            }
        }
        return new Promise((resolve, reject) => {
            requestLogger.logRequest(config, resolve, reject);
            resolve(config);
        });
    },
    (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response) {
            if (error.response.status === 422) {
                if (error.response.data?.message) {
                    $toast.error(error.response.data.message, {
                        position: 'top-right'
                    });
                } else {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        const jsonData = JSON.parse(event?.target?.result);
                        $toast.error(jsonData?.message, {
                            position: 'top-right'
                        });
                    };
                    reader.readAsText(error.response.data);
                }
                return Promise.reject(new ValidationError(error.response.data));
            }

            if (error.response.status === 403 && error.response.data.code === 3) {
                $toast.error(error.response.data.message || 'Error', {
                    position: 'top-right'
                });
            }

            if (error.response.status === 401) {
                $toast.error(error.response.data.message, {
                    position: 'top-right'
                });
                JwtService.unsetToken('auth');
                window.location.href = '/auth/login';
                return;
            }

            if (
                error.response.status === 500 ||
                error.response.status === 429 ||
                error.response.status === 400
            ) {
                $toast.error(
                    error.response.data?.message || error.response.data.error?.message || 'Error',
                    {
                        position: 'top-right'
                    }
                );
                throw new ValidationError(error.response);
            }

            if (error.response.status === 502) {
                $toast.error(error.message, {
                    position: 'top-right'
                });
            }
        }
        throw error;
    }
);

export default axiosInstance;
