import { defineStore } from 'pinia';
import { RemoteObjects } from '@/api/remoteObjects.js';
import { useToast } from 'vue-toast-notification';

const $toast = useToast();

export const remoteObjectsStore = defineStore('storeRemoteObjects', {
  state: () => ({
    allRemoteObjectsFiltered: [],
    allRemoteObjects: [],
    allRemoteObjectsMax: 0,
    filter: {
      integratorFilterModel: '',
      companyFilterModel: []
    },
    count: 0,
    sortField: '',
    sortDesc: ''
  }),

  actions: {
    async postAllRemoteObjects({ from = 0, count = 100, companies = [], value, field, fields }, pagination) {
      try {
        if (!this.allRemoteObjects.length || pagination) {
          const formData = { from, count, companies, value, field, fields };

          if (this.filter.integratorFilterModel) {
            formData.dealer = { id: this.filter.integratorFilterModel };
          }
          if (this.filter.companyFilterModel.length) {
            formData.companies = this.filter.companyFilterModel.map((comp) => ({ id: comp }));
          }
          if (this.sortDesc !== '') {
            formData.sortDesc = this.sortDesc;
            formData.sortField = this.sortField;
          }

          const response = await RemoteObjects.postAllRemoteObjects(formData);
          this.allRemoteObjects = response.list;
          this.allRemoteObjectsFiltered = response.list;
          this.allRemoteObjectsMax = response.max;
          return response;
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    deleteRemoteObjects(ids) {
      RemoteObjects.deleteRemoteObjects(ids).then(() => {
        ids.forEach((id) => {
          this.allRemoteObjectsFiltered.forEach((val, key) => {
            if (val.id === id) {
              this.allRemoteObjectsFiltered.splice(key, 1);
            }
          });
        });
      });
    },
    async deleteObjectsPermanently(id) {
      try {
        const response = await RemoteObjects.deleteObjectsPermanently(id);
        this.allRemoteObjectsFiltered.forEach((val, key) => {
          if (val.id === id) {
            this.allRemoteObjectsFiltered.splice(key, 1);
          }
        });
        this.allRemoteObjectsMax -= 1;
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },
    async postRestoreRemoteObject(ids) {
      try {
        const response = await RemoteObjects.postRestoreRemoteObject(ids);
        $toast.success(`${ids.length}-объектов восстановлено`, {
          position: 'top-right'
        });
        ids.forEach((id) => {
          this.allRemoteObjectsFiltered.forEach((val, key) => {
            if (val.id === id) {
              this.allRemoteObjectsFiltered.splice(key, 1);
              this.allRemoteObjectsMax -= 1;
            }
          });
        });
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },
    async clearRemoteObjectsFilter() {
      this.filter.integratorFilterModel = '';
      this.filter.companyFilterModel = [];
      const clearData = {
        companies: [],
        count: 100,
        field: '',
        fields: [],
        from: 0,
        model: 'delunits',
        value: '',
        conditions: []
      };
      try {
        await this.postAllRemoteObjects(clearData, true);
      } catch (error) {
        console.error('Error during clearing remote objects:', error);
      }
    },
    restoreObject(id) {
      this.allRemoteObjects = this.allRemoteObjectsFiltered = this.allRemoteObjectsFiltered.filter((item) => item.id !== id);
    },
    updateSortedRemoteObjects(payload) {
      this.allRemoteObjectsFiltered = payload.list;
    },
    CLEAR_REMOTE_OBJECTS_FILTER() {
      console.log('CLEAR_REMOTE_OBJECTS_FILTER');
      this.filter.integratorFilterModel = '';
      this.filter.companyFilterModel = [];
    }
  },

  getters: {
    getAllRemoteObjects: (state) => state.allRemoteObjectsFiltered
  }
});
