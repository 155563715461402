import { createApp } from 'vue';
import App from './App.vue';
import vClickOutside from 'click-outside-vue3';
import './assets/sass/main.scss';
import './assets/sass/styles/index.scss';

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';

import { FocusTrap } from 'focus-trap-vue';
import { bootstrap } from '@/lib/modules';
import { install } from '@/lib/plugins';
import modules from '@/modules';
import plugins from '@/plugins';
import vMaskV3 from './directives/vMask.js';


import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

const app = createApp(App)
  .use(ElementPlus)
  .use(vClickOutside)
  .component('FocusTrap', FocusTrap)
  .directive('mask', vMaskV3);

install(
  bootstrap(
    app.component('VueCtkDateTimePicker', VueCtkDateTimePicker),
    modules
  ),
  plugins
).mount('#app');
