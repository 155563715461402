export const URL = import.meta.env.VITE_APP_API_URL;
export const API_URL = `/api_v1/`;
export const CLIENT_ID = import.meta.env.VITE_APP_API_CLIENT_ID;
export const CLIENT_SECRET = import.meta.env.VITE_APP_API_CLIENT_SECRET;
export const PUSHER_KEY = import.meta.env.VITE_APP_PUSHER_APP_KEY;
export const PUSHER_CLUSTER = import.meta.env.VITE_APP_PUSHER_APP_CLUSTERY;
export default {
  URL,
  API_URL,
  CLIENT_ID,
  CLIENT_SECRET,
  PUSHER_KEY,
  PUSHER_CLUSTER
};
