<template>
  <el-config-provider :locale="locale">
    <div class="app">
      <component v-if="layout" :is="layout" />
      <router-view v-slot="{ Component }">
        <transition name="fade">
          <div
            v-if="bootstrapped && getExist"
            class="content-route min-h-full w-full"
          >
            <component :is="Component" />
          </div>
        </transition>
      </router-view>
    </div>
  </el-config-provider>
</template>

<script lang="ts" setup>
import { ElConfigProvider } from 'element-plus';
import { getCookie, setElementLocale } from '@/utils/elementPlusLocale.js';
import { computed, onBeforeMount, ref, shallowRef, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '@/store/auth';
import { useProgress } from '@/lib/ui/hooks/progress';
import { useSleep } from '@/lib/ui/hooks/sleep';
import { userStore } from '@/store/modules/user.js';
import { useWebsocketHandler } from '@/services/socket/webSocketHandler';
import { companyStore } from '@/store/modules/company.js';

import LayoutDefault from '@/layouts/default.vue';
import JwtService from '../src/common/jwt.service';

const router = useRouter();
const route = useRoute();
const UserStore = userStore();
const { start } = useWebsocketHandler();
const CompanyStore = companyStore();

const auth = useAuthStore();
const progress = useProgress();
const sleep = useSleep();
const bootstrapped = ref(false);
const layout = shallowRef(null);

router.beforeEach((from, to, next) => {
  if (from.fullPath === '/auth/login') {
    if (JwtService.getToken('auth')) {
      next('/');
    }
    UserStore.setExist(true);
  }
  if (from.fullPath === '/auth/recovery') {
    if (JwtService.getToken('auth')) {
      next('/');
    }
    UserStore.setExist(true);
  }

  if (from.fullPath === '/auth/changePassword') {
    UserStore.setExist(true);
  }

  progress.start();
  next();
});

const getExist = computed(() => {
  return UserStore.getExist;
});
const locale = computed(() => {
  const lang = getCookie('PLAY_LANG') ? getCookie('PLAY_LANG') : UserStore.$state.me.lang.key;
  return setElementLocale(lang);
});

onBeforeMount(async () => {
  try {
    progress.start();
    await Promise.all([router.isReady(), auth.bootstrap(), sleep(500)]);
  } finally {
    progress.finish();
    bootstrapped.value = true;
    router.afterEach(() => {
      progress.finish();
    });

    start();
  }
});
// CompanyStore.count = 0
watch(
  () => route.meta,
  async (meta: { layout }) => {
    const name = meta.layout;

    try {
      await import(`./layouts/${name}.vue`);

      layout.value = LayoutDefault;
    } catch (e) {
      layout.value = '';
    }
  },
  { immediate: true }
);
watch(
  () => route.fullPath,
  () => {
    CompanyStore.count = 0;
  }
);
</script>

<style scoped>
.app {
  display: flex;
  overflow: hidden;
}

.content-route {
  min-height: 100vh;
}
</style>
