import axiosInstance from '../utils/axios.js';

export const RemoteObjects = {
  async postAllRemoteObjects({ from, count, companies, value, field, fields, dealer, sortField, sortDesc }) {
    const formData = {
      fields: ['id', 'name', 'imei', 'company_id', 'deleted_at', 'phonenumber', 'phonenumber2', 'sensors_count'],
      model: 'delunits',
      from,
      count,
      value: value || '',
      field: field || '',
      companies: companies || []
    };
    if (dealer) {
      formData.dealer = dealer;
    }
    if (sortDesc !== '') {
      formData.sortDesc = sortDesc;
      formData.sortField = sortField;
    }
    const { data } = await axiosInstance.post('/admin_query', formData);

    return data;
  },
  async deleteRemoteObjects(ids) {
    const { data } = await axiosInstance.delete('/admin_query', {
      data: {
        ids
      }
    });

    return data;
  },
  async deleteObjectsPermanently(id) {
    const { data } = await axiosInstance.delete(`/units/physical/${id}`, {
      data: {
        id
      }
    });

    return data;
  },

  async postRestoreRemoteObject(ids) {
    const { data } = await axiosInstance.post('/restore', {
      type: 'units',
      ids
    });

    return data;
  }
};
