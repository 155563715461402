import {
  hasErrorResponse,
  isAxiosError,
  NetworkError,
  TooManyAttemptsError,
  ValidationError
} from './errors';
import { getAccessToken } from '@/lib/storage/accessToken';
import { merge } from 'lodash';
import axios from 'axios';
import { API_URL } from '@/common/config.js';

const getDefaultAxiosConfig = () => {
  const token = getAccessToken();

  return {
    baseURL: API_URL,
    headers: { Authorization: token ? `Bearer ${token.accessToken}` : false }
  };
};

export const getAxiosInstance = (
  config = {}
) => {
  const instance = axios.create(merge(getDefaultAxiosConfig(), config));

  instance.interceptors.response.use(
    (res) => res,
    (err) => {
      if (!isAxiosError(err)) {
        throw err;
      }

      if (!hasErrorResponse(err)) {
        throw new NetworkError();
      }

      if (err.response.status === 429) {
        throw new TooManyAttemptsError();
      }

      if (err.response.status === 422) {
        throw new ValidationError(err);
      }

      throw err;
    }
  );

  return instance;
};
