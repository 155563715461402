class RequestLogger {
    constructor() {
        this.requests = [];
    }

    logRequest(config, resolve, reject) {
        this.requests.push({ config, resolve, reject });
    }

    clearRequests() {
        this.requests = [];
    }

    retryRequests(axiosInstance) {
        const promises = this.requests.map(({ config, resolve, reject }) =>
            axiosInstance.request(config).then(resolve).catch(reject)
        );
        this.clearRequests();
        return Promise.all(promises);
    }
}

const requestLogger = new RequestLogger();
export default requestLogger;
