import { defineStore } from 'pinia';
import { Search } from '@/api/globalSearch.js';

export const searchStore = defineStore('storeSearch', {
  state: () => ({
    allResults: [],
    allResultsFiltered: [],
    allResultsMax: 0,
    loading: false,
    type: '',
    apiData: {},
    ifFilterActive: false
  }),

  actions: {
    setFilterActive(isActive) {
      this.ifFilterActive = isActive;
    },
    async allResult(data, type) {
      try {
        this.loading = true;
        this.type = type;
        this.apiData = data;
        const response = await Search.allResult(data);
        if (data.url === 'bill_tariff_admin') {
          this.allResults = response;
          this.allResultsFiltered = response;
          this.allResultsMax = response.length;
        } else if (data.url === 'bill_transactions_admin') {
          this.allResults = response.transactions;
          this.allResultsFiltered = response.transactions;
          this.allResultsMax = response.max;
        } else {
          this.allResults = response.list;
          this.allResultsFiltered = response.list;
          this.allResultsMax = response.max;
        }
        this.loading = false;
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },
    async deleteObject(ids) {
      try {
        const response = await Search.deleteResult(ids);
        ids.forEach((id) => {
          this.allResultsFiltered.forEach((val, key) => {
            if (val.id === id) {
              this.allResultsFiltered.splice(key, 1);
            }
          });
          this.allResultsMax -= 1;
        });
        return response;
      } catch (e) {
        throw new Error(e);
      }
    }
  },

  getters: {
    getAlResults: (state) => state.allResultsFiltered,
    getType: (state) => state.type,
    getLoading: (state) => state.loading,
    getIfFilterActive: (state) => state.ifFilterActive
  }
});
