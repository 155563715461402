const module = {
  routes: [
    {
      path: '/auth',
      component: () => import('@/modules/auth/pages/Auth.vue'),
      children: [
        {
          path: '/auth/login',
          component: () => import('@/modules/auth/pages/login/AuthLogin.vue')
        },
        {
          path: '/auth/recovery',
          component: () => import('@/modules/auth/pages/recovery/AuthRecovery.vue')
        },
        {
          path: '/auth/access',
          component: () => import('@/modules/auth/pages/recovery/AuthRecoveryAccess.vue')
        },
        {
          path: '/auth/changePassword',
          component: () => import('@/modules/auth/pages/login/AuthChangePassword.vue')
        }
      ]
    }
  ]
};

export default module;
