import { isNull } from 'lodash';

export const get = (key) => {
  const val = localStorage.getItem(key);

  if (!val) {
    return null;
  }

  return JSON.parse(val);
};

export const set = (key, val) => {
  if (isNull(val)) {
    localStorage.removeItem(key);
    return;
  }

  localStorage.setItem(key, JSON.stringify(val));
};