import axiosInstance from '../utils/axios.js';
import JwtService from '@/common/jwt.service';

export const User = {
  async login(formData) {
    return await axiosInstance.post('/login', formData);
  },

  async logout() {
    await axiosInstance
      .put('/logout')
      .then(() => {
        JwtService.unsetToken('auth');
      });
  },

  async getMe() {
    const { data } = await axiosInstance.get('/me');

    return data;
  },

  async resetPassword(form) {
    const { data } = await axiosInstance.put(`/reset_password/${form.id}`, {
      newPassword: form.password
    });

    return data;
  },

  async changeMyPassword(form) {
    const { data } = await axiosInstance.put(`/change_my_password`, {
      password: form.password,
      newPassword: form.newPassword
    });

    return data;
  },

  async createUser(params) {
    const { data } = await axiosInstance.post('/users', params);

    return data;
  },

  async getCompanyUsers(activeCompanyId) {
    const form = {
      model: 'users',
      companies: [{ id: activeCompanyId }],
      fields: ['id', 'name', 'email', 'company_user', 'groups_ids', 'role']
    };
    const { data } = await axiosInstance.post('/auth_admin_query', form);
    return data;
  },

  async getUserId(id, activeCompanyId = '') {
    const url = activeCompanyId ? `/company/${activeCompanyId}/users?ids=${id}` : `/company/users?ids=${id}`;
    const { data } = await axiosInstance.get(url);
    return data;
  },
  async setUserId(formData, activeCompanyId) {
    const { data } = await axiosInstance.put(`/company/${activeCompanyId}/users/${formData.id}`, formData);
    return data;
  },
  async updateUser(params) {
    const { data } = await axiosInstance.put(`/users/${params.id}`, params);

    return data;
  },
  async postFilterQuery(formData) {
    const { data } = await axiosInstance.post('/auth_admin_query', formData);
    return data;
  },
  async postUsersQuery({
                         model,
                         count,
                         from,
                         companies,
                         filed,
                         value,
                         dealer,
                         sortField,
                         sortDesc
                       }) {
    const requestData = {
      model: model || 'users',
      fields: [
        'id',
        'name',
        'email',
        'details',
        'telegram_chat_id',
        'code',
        'phone',
        'companies',
        'role'
      ],
      from,
      count,
      field: filed || '',
      value: value || ''
    };

    if (dealer) {
      requestData.dealer = dealer;
    }
    if (companies && companies.length > 0) {
      requestData.companies = companies;
    }
    if (sortDesc !== '') {
      requestData.sortDesc = sortDesc;
      requestData.sortField = sortField;
    }

    const { data } = await axiosInstance.post('/auth_admin_query', requestData);
    return data;
  },

  async postPhotos(formData) {
    const { data } = await axiosInstance.post('/users/photos', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return data;
  },

  async getAllProperties() {
    const { data } = await axiosInstance.get('/properties');

    return data;
  },
  async getAllConfigs() {
    const { data } = await axiosInstance.get('/configs');

    return data;
  },

  async deleteUserCompany({ ids, companies }) {
    // const a = JSON.stringify(companies)
    const { data } = await axiosInstance.delete(`company/users/${ids}`, {
      data: {
        companies: companies
      }
    });

    return data;
  },

  async deleteUser({ ids }) {
    const { data } = await axiosInstance.delete(`/users/${ids}`);

    return data;
  },

  async recovery(formData) {
    const { data } = await axiosInstance.post('/recovery', formData);
    return data;
  },

  async billCreate(amount, payment) {
    const { data } = await axiosInstance.post(
      '/bill_create_order',
      amount,
      payment
    );

    return data;
  },

  async getAllActions() {
    const { data } = await axiosInstance.get('/actions');

    return data;
  },

  async inviteAll(invite) {
    const { data } = await axiosInstance.post('/company/invite/all', invite);

    return data;
  },

  async recoveryQr(id) {
    try {
      const { data } = await axiosInstance.get(`recovery/qr/${id}`);
      return data;
    } catch (error) {
      return error;
    }
  }
};
