import { fetchAuthProfile } from '@/api/auth/user';
import { issueRefreshTokenGrantAccessToken } from '@/api/oauth';
import dayjs from 'dayjs';
import { defineStore } from 'pinia';
import { getAccessToken, setAccessToken } from '@/lib/storage/accessToken';


const mapAccessToken = (token) => ({
  accessToken: token.accessToken,
  refreshToken: token.refreshToken,
  expiresAt: dayjs().add(token.expiresIn, 'seconds').toString()
});

export const useAuthStore = defineStore('auth', {
  state: () => ({
    _token: getAccessToken(),
    _profile: null
  }),
  getters: {
    token: (s) => s._token,
    isExpiring: (s) => dayjs(s._token?.expiresAt).diff(dayjs(), 'hours') < 1,
    profile: (s) => s._profile
  },
  actions: {
    async bootstrap() {
      if (!this._token) {
        return;
      }

      try {
        if (dayjs(this._token?.expiresAt).diff(dayjs(), 'hours') < 1) {
          const token = await issueRefreshTokenGrantAccessToken({
            refreshToken: this._token.refreshToken
          });

          this._token = mapAccessToken(token);
          setAccessToken(this.token);
        }

        this._profile = await fetchAuthProfile();
      } catch (err) {
        this._token = null;
        this._profile = null;

        setAccessToken(null);
      }
    },
    async authorize(token) {
      try {
        this._token = mapAccessToken(token);
        setAccessToken(this._token);

        this._profile = await fetchAuthProfile();
      } catch (err) {
        this._token = null;
        this._profile = null;

        setAccessToken(null);

        throw err;
      }
    },
    async refetch() {
      try {
        this._profile = await fetchAuthProfile();
      } catch (err) {
        this._token = null;
        this._profile = null;

        setAccessToken(null);

        throw err;
      }
    },
    async logout() {
      this._token = null;
      this._profile = null;

      setAccessToken(null);
    }
  }
});
