import axiosInstance from '../utils/axios.js';

export const Rebroadcasts = {
  async postAllRebroadcasts({ from, count, value, field, fields, companies, dealer, sortField, sortDesc, conditions }) {
    const formData = {
      model: 'retranslators',
      from,
      count,
      field,
      fields,
      value: value || '',
      companies: companies || [],
      conditions: conditions || []
    };
    if (dealer) {
      formData.dealer = dealer;
    }
    if (sortDesc !== '') {
      formData.sortDesc = sortDesc;
      formData.sortField = sortField;
    }
    const { data } = await axiosInstance.post('/admin_query', formData);

    return data;
  },
  async filterRebroadcasts(formData) {
    const { data } = await axiosInstance.post('/admin_query', formData);
    return data;
  },
  async deleteRebroadcasts(ids) {
    const { data } = await axiosInstance.delete(`/retranslators/${ids}`);

    return data;
  },

  async updateRebroadcasts(params) {
    const { data } = await axiosInstance.put(`/retranslators/${params.id}`, params);

    return data;
  },

  async createRebroadcast(form) {
    const { data } = await axiosInstance.post('/retranslators', {
      ...form
    });

    return data;
  },

  async getRebroadcastLogs(params) {
    const { data } = await axiosInstance.post(`/retranslators/${params.id}/logs`, {
      ...params
    });

    return data;
  },

  async getRebroadcast(id) {
    const { data } = await axiosInstance.get(`/retranslators?ids=${id}`);

    return data;
  }
};
