import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import ru from './locales/ru.json';
import kz from './locales/kz.json';
import bg from './locales/bg.json';

const install = (app) => {
  app.use(
    createI18n({
      legacy: false,
      locale: 'ru',
      fallbackLocale: 'ru',
      messages: { en, ru, kz, bg}
    })
  );
};

export default install;
