<template>
  <div class="layout__header">
    <div class="layout__header-user user">
      <div class="user__wrapper">
        <div class="user__substrate">
          <img v-if="data.photo && data.photo.length" src="" alt="" />
          <span v-else>{{ userAvatarName }}</span>
        </div>
        <div class="user__info">
          <div class="user__title text-ellipsis">
            {{ data.name }}
          </div>
          <div class="user__subtitle text-ellipsis">Email: {{ data.email }}</div>
          <div class="user__subtitle text-ellipsis user__logout" @click="logout">
            <span>{{ $t("log_out_modal.go_out") }}</span>
          </div>
        </div>
      </div>
      <div class="user__config" @click="$emit('openConfig')">
        <img src="@/assets/svg/config.svg" alt="Config">
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue';

import { User } from '@/api/user.js';

import { useRouter } from 'vue-router';

const props = defineProps({
  data: {
    type: Object,
    default: () => {
    }
  }
});
const router = useRouter();
const logout = () => {
  User.logout().then(() => {
    router.push('/auth/login');
    window.location.reload();
  });
};

const emojiRegex = /[\u{1F300}-\u{1F5FF}]|[\u{1F600}-\u{1F64F}]|[\u{1F680}-\u{1F6FF}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]|\uD83D[\uDE80-\uDEFF]/u;

const userAvatarName = computed(() => {
  const name = Object(props.data).name;
  const emojiIndex = Array.from(name).findIndex(chart => emojiRegex.test(chart as string));
  if (emojiIndex !== -1 && emojiIndex == 0) { // найден эмодзи в начале строки
    return name.slice(emojiIndex, emojiIndex + 2);
  } else {
    return name.slice(0, 1).toUpperCase();
  }
});
</script>

<style scoped lang="scss">
.layout__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 14px;
  width: 100%;
  background: #343434;
  box-sizing: border-box;

  &-user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}

.user {
  &__wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__substrate {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 52px;
    height: 52px;
    font-size: 16px;
    color: #460b6a;
    background: #edcfff;
    border-radius: 100%;
    box-sizing: border-box;
    font-weight: 600;
    line-height: 120%;
  }

  &__title {
    max-width: 200px;
    font-size: 20px;
    white-space: nowrap;
    color: #fff;
    font-weight: 600;
    line-height: 24px;
  }

  &__subtitle {
    max-width: 200px;
    font-size: 12px;
    white-space: nowrap;
    color: #a9a9a9;
    font-weight: 400;
    line-height: 120%;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 12px;
  }

  &__config {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    border-radius: 4px;
    transition: all .3s ease-in-out;

    img{
      width: 100%;
    }

    &:hover{
      cursor: pointer;
      background-color: #595959;
    }
  }

  &__logout {
    font-size: 12px;
    color: #df6666;
    cursor: pointer;
    line-height: 14.4px;
  }
}
</style>
