import { getAxiosInstance } from '@/api/index.js';

export const fetchAuthProfile = async () => {
  const { data } = await getAxiosInstance().get(`users/getUser`);
  return data.data;
};

export const userUpdate = async (userId, info) => {
  const { data } = await getAxiosInstance().put(
    `/users/${userId}`, info);

  return data.data;
};

export const createUser = async (info) => {
  const { data } = await getAxiosInstance().post(
    `/users`, info);

  return data.data;
};

export const userChangePassword = async (userId, passwordForm) => {
  const { data } = await getAxiosInstance().put(
    `/users/${userId}/change-password`, passwordForm);
  return data.status;
};

export const userPasswordCheck = async (userId, password) => {
  const { data } = await getAxiosInstance().post(
    `/users/${userId}/check-password`, { password: password });
  return data.status;
};

export const fetchUsers = async () => {
  const { data } = await getAxiosInstance().get('/users');

  return data.data;
};

export const updateUserRole = async (userId, roleId) => {
  const { data } = await getAxiosInstance().post(`/users/update-role/${userId}/${roleId}`, {});
  return data.data;
};

export const userDelete = async (userId) => {
  const { data } = await getAxiosInstance().delete(`/users/${userId}`);
  return data.data;
};
