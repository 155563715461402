const module = {
  routes: [
    {
      path: '/users',
      component: () => import('@/modules/users/pages/index.vue'),
      children: [],
      meta: {
        layout: 'default',
        requiresAuth: true
      }
    }
  ]
};

export default module;
