import { defineStore } from 'pinia';
import { User } from '@/api/user.js';
import JwtService from '@/common/jwt.service.js';

export const userStore = defineStore('storeUser', {
  state: () => ({
    me: {
      id: '',
      name: '',
      lang: {},
      active_company: {},
      active_company_id: '',
      companies: [],
      approved_accounts: {},
      details: '',
      email: '',
      photos: [],
      properties: [],
      role: {}
    },
    roles: [],
    languages: [],
    userById: null,
    usersCompany: [],
    usersQuery: [],
    usersQueryMax: 0,
    usersQueryFiltered: null,
    exist: false,
    typeOrganization: [],
    timezones: [],
    payModules: [],
    tariffType: [],
    config: {},
    users: [],
    sendTypes: [],
    filter: {
      integrator: null,
      companies: [],
    },
    count: 0,
    sortField: '',
    sortDesc: '',
    smsGatewayType: [],
    timezone_key: '',
    operationsType:[],
    maps: {
      web: [],
      ios: [],
      android: [],
    },
    companyBlockTypes: [],
    terminalBlockTypes: [],
  }),

  actions: {
    setExist(data) {
      this.exist = data;
    },
    async login(form) {
      try {
        const response = await User.login(form);
        if (response && response.status === 200) {
          JwtService.setToken('auth', true);
        }
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },
    async getMe() {
      try {
        const response = await User.getMe();
        this.setExist(true);
        this.me = response;
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },

    getUserInfo(id) {
      User.getUserId(id).then((res) => {
        resolve(res);
      });
    },

    async resetPassword(form) {
      try {
        const response = await User.resetPassword(form);
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },

    async changeMyPassword(form) {
      try {
        const response = await User.changeMyPassword(form);
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },

    async clearUsersFilters() {
      this.filter.status = null;
      this.filter.integrator = null;

      const clearData = {
        companies: [],
        count: 100,
        field: '',
        fields: [],
        from: 0,
        model: 'users',
        value: '',
        conditions: []
      };
      try {
        await this.getUsersQuery(clearData);
      } catch (error) {
        console.error('Error during clearing users:', error);
      }
    },

    getAllProperties() {
      User.getAllProperties().then((res) => {
        this.languages = res.filter((el) => el.type === 'languages');
        this.roles = res.filter((el) => el.type === 'roles');
        this.typeOrganization = res.filter((el) => el.type === 'dealer_types');
        this.timezones = res.filter((el) => el.type === 'timezones');
        this.payModules = res.filter((el) => el.type === 'payment_types');
        this.tariffType = res.filter((el) => el.type === 'tariff_type');
        this.sendTypes = res.filter((el) => el.type === 'send_types');
        this.smsGatewayType = res.filter((el) => el.type === 'sms_gateway_type');
        this.operationsType = res.filter((el) => el.type === 'billing_operation_type');
        this.maps.web = res.filter((el) => el.type === 'web_maps');
        this.maps.ios = res.filter((el) => el.type === 'ios_maps');
        this.maps.android = res.filter((el) => el.type === 'android_maps');
        this.companyBlockTypes = res.filter((el) => el.type === 'block_type');
        this.terminalBlockTypes = res.filter((el) => el.type === 'terminal_block_type');
      });
    },
    async getCompanyUsers(activeCompanyId) {
      try {
        const response = await User.getCompanyUsers(activeCompanyId);
        this.usersCompany = response.list;
        return response.list;
      } catch (e) {
        console.log(e);
      }
    },
    getAllConfigs() {
      User.getAllConfigs().then((res) => {
        this.config = res;
      });
    },
    async getFilterQuery(formData) {
      try {
        const response = await User.postFilterQuery(formData);
        this.usersQuery = response.list;
        this.usersQueryFiltered = response.list;
        this.usersQueryMax = response.max;
        return response;
      } catch (e) {
        throw new Error(e);
      }

    },
    async getUsersQuery({
                          from = 0,
                          count = 100,
                          companies,
                          filed,
                          value,
                          model
                        }) {
      try {
        const formData = {
          from,
          count,
          filed,
          value,
          model
        };

        formData.conditions = [];

        if (this.sortDesc !== '') {
          formData.sortDesc = this.sortDesc;
          formData.sortField = this.sortField;
        }

        if (this.filter.integrator) {
          formData.dealer = { id: this.filter.integrator };
        }

        if (this.filter.companies.length) {
          formData.companies = this.filter.companies.map((id) => {
            return { id };
          });
        }

        const response = await User.postUsersQuery(formData);

        this.usersQuery = response.list;
        this.usersQueryFiltered = response.list;
        this.usersQueryMax = response.max;

        return response;
      } catch (e) {
        throw new Error(e);
      }
    },

    async createUser(form) {
      try {
        const response = await User.createUser(form);
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },

    async filterUser(data) {
      try {
        const response = await this.getUsersQuery(
          {
            from: 0,
            count: 100,
            value: data?.value,
            filed: data?.valueFilter?.value || ''
          }, true);
        return response;
      } catch (e) {
        throw new Error(e);
      }
      // this.usersQueryFiltered = this.usersQuery.filter(val => {
      //     let filteredItem = val
      //     const type = data.valueFilter?.value || 'name'
      //     const value = data.value || ''
      //     const role = data.filter.value
      //     if (role) {
      //         if (val.companies && val.companies.length) {
      //             if (val.companies[0].role.value !== role) {
      //                 filteredItem = null
      //             }
      //         } else {
      //             filteredItem = null
      //         }
      //
      //     }
      //
      //     if (filteredItem) {
      //         return filteredItem[type] && String(filteredItem[type]).toLowerCase().includes(String(value).toLowerCase())
      //     }
      //
      // })
    },

    async deleteUserCompany({ ids, companies, active_company_id }) {
      try {
        const response = await User.deleteUserCompany({ ids, companies });
        let companiesArray = [];
        if (active_company_id) {
          companiesArray = [{ id: active_company_id }];
        }

        await this.getUsersQuery({ from: 0, count: 100, companiesArray });
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },

    async deleteUser({ ids, companies, active_company_id }) {
      if (!companies) {
        return false
      }
      try {
        const response = await User.deleteUser({ ids, companies });
        let companies = [];
        if (active_company_id) {
          companies = [{ id: active_company_id }];
        }

        await this.getUsersQuery({ from: 0, count: 100, companies });
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },

    async updateUser(form) {
      try {
        const response = await User.updateUser(form);
        if (
          this.usersQueryFiltered === null ||
          !this.usersQueryFiltered.length
        ) {
          this.usersQueryFiltered = JSON.parse(
            JSON.stringify(this.usersQuery)
          );
        }
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },

    async inviteAllCompany(form) {
      try {
        const response = await User.inviteAll(form);
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },

    async WEBSOCKET_CHANGE({ id, operation }) {
      if (operation === 'DELETE') return this.DELETE(id);

      const data = await User.getUserId(id);
      this.userById = data[0];
      operation === 'POST' ? this.ADD(data[0]) : this.UPDATE(data[0]);
    },
    async getUserByIdHandler({ id, activeCompanyId }) {
      const data = await User.getUserId(id, activeCompanyId);
      this.userById = data[0];
    },
    async setUserByIdHandler(formData, activeCompanyId) {
      const data = await User.setUserId(formData, activeCompanyId);
      this.userById = data[0];
    },
    ADD(user) {
      if (!this.usersQueryFiltered.some((x) => x.id === user.id)) {
        this.usersQuery = this.usersQueryFiltered = [
          ...this.usersQueryFiltered,
          user
        ];
      }
    },
    UPDATE(user) {
      const idx = this.usersQueryFiltered.findIndex(
        (elem) => elem.id === user.id
      );

      if (idx === -1) return;

      this.usersQuery = this.usersQueryFiltered = [
        ...this.usersQueryFiltered.slice(0, idx),
        user,
        ...this.usersQueryFiltered.slice(idx + 1)
      ];
    },
    DELETE(id) {
      this.usersQuery = this.usersQueryFiltered =
        this.usersQueryFiltered.filter((item) => item.id !== id);
    },
    SET_USERS() {
      this.usersQuery = [];
    },
    SET_COMPANY_USERS(final) {
      this.usersCompany = final;
    },
    updateSortedUsers(payload) {
      this.usersQueryFiltered = payload.list;
    },
    SET_TIMEZONE_KEY(payload) {
      this.timezone_key = payload
    },
    CLEAR_USER_FILTER() {
      console.log('CLEAR_USER_FILTER')
      this.filter.integrator = null;
      this.filter.companies = [];
    }
  },

  getters: {
    getUsersOfCompany: (state) => state.usersCompany,
    getUsersQueryItems: (state) =>
      state.usersQueryFiltered ? state.usersQueryFiltered : state.usersQuery,
    getRoles: (state) => state.roles,
    getExist: (state) => state.exist,
    getTypeOrganization: (state) => state.typeOrganization,
    getTimezones: (state) => state.timezones,
    getPayModules: (state) => state.payModules,
    getTariffType: (state) => state.tariffType,
    getAdminModules: (state) => state.adminModules,
    getCurrentUserRole: (state) => state.me.role,
    getActiveCompanyId: (state) => state.me.active_company_id,
    getSendTypes: (state) => state.sendTypes,
    getUser: (state) => state.userById,
    getSmsGatewayType: (state) => state.smsGatewayType,
    getLang: (state) => state.me.lang.key,
    getTimezoneKey: (state) => state.timezone_key,
    getOperationsType: (state) => state.operationsType,
    getMaps: (state) => state.maps,
    getCompanyBlockTypes: (state) => state.companyBlockTypes,
    getTerminalBlockTypes: (state) => state.terminalBlockTypes,
  }
});
