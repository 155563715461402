import axiosInstance from '../utils/axios.js';

export const Company = {
  async settingsCompany(form) {
    const { data } = await axiosInstance.put('/company/' + form.id, {
      ...form
    });

    return data;
  },
  async getCompanyChangeId(id) {
    const { data } = await axiosInstance.post('/company/change/' + id);

    return data;
  },
  async sendInvite(form) {
    const { data } = await axiosInstance.post('/company/invite', {
      ...form
    });

    return data;
  },

  async postSessions(params) {
    const { data } = await axiosInstance.post('/company/sessions/query', {
      ...params
    });

    return data;
  },

  async postItemSessions({ id, from }) {
    const { data } = await axiosInstance.post(`/company/${id}/sessions/query`, {
      from,
      first: 0,
      count: 100
    });

    return data;
  },

  async createCompanies(form) {
    const { data } = await axiosInstance.post('/company/create', {
      ...form
    });

    return data;
  },

  async updateCompanies(params) {
    const { data } = await axiosInstance.put(`/company/${params.id}`, params);

    return data;
  },

  async deleteCompanies(ids) {
    const { data } = await axiosInstance.delete(`/company/schedule_delete`, {
      data: {
        ids: ids
      }
    });

    return data;
  },

  async copyCompanies(payload) {
    const { data } = await axiosInstance.post(`/copy`, {
      ...payload
    });

    return data;
  },

  async createQRCode(id) {
    const { data } = await axiosInstance.post(`/boxes/create_code`, {
      units: [{ id }]
    });

    return data;
  },

  async loadCompanies({ id, file }) {
    const files = new FormData();
    files.append('file', file);
    const { data } = await axiosInstance.post(`/loadwln/${id}/units/analyze_terminals`, files, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return data;
  },

  async postAllCompanies(form) {
    const formData = {
      model: 'companies',
      fields: form.fields || ['id', 'name', 'timezone_key', 'users_count', 'creator', 'amount', 'tariff.units', 'dealer_id', 'billing', 'units'],
      from: form.from || 0,
      count: form.count || 100,
      value: form.value || '',
      field: form.field || '',
      conditions: form.conditions || [],
      companies: form.companies || []
    };
    if (form.dealer && form.dealer.id) {
      formData.dealer = form.dealer;
    }
    if (form.sortDesc !== '') {
      formData.sortDesc = form.sortDesc;
      formData.sortField = form.sortField;
    }
    const { data } = await axiosInstance.post('/auth_admin_query', formData);

    return data;
  },
  async getCompanyById(id) {
    const { data } = await axiosInstance.get('/company', { params: { ids: id } });

    return data;
  },

  async billTariffAdmin(formData) {
    const { data } = await axiosInstance.post('/bill_tariff_admin', formData);

    return data;
  },

  async billTariffAdminUpdate(formData) {
    const { data } = await axiosInstance.put('/bill_tariff_admin', formData);
    return data;
  },

  // /news
  async postNews(formData) {
    const { data } = await axiosInstance.post('/news', formData);
    return data;
  },

  // Получение доступных ролей по компании
  async getRolesCompany(formData) {
    const { data } = await axiosInstance.get(`/company/${formData}/roles`);
    return data;
  },

  // Изменение роли в компании
  async changeRoleCompany(formData) {
    const { data } = await axiosInstance.put(`/users/${formData.id}/change_role`, {
      role: formData.role,
      companies: formData.companies
    });
    return data;
  },

  // Добавление доступных пользователей в компанию
  async addUserCompany(formData) {
    const { data } = await axiosInstance.post(`/company/${formData.id}/add_users`, {
      users: formData.users,
      role: formData.role
    });
    return data;
  },

  // Изменение роли пользователя во всех его компаниях
  async changeRollAll(formData) {
    const { data } = await axiosInstance.put(`/users/${formData.id}/change_role_all`, {
      role: formData.role
    });
    return data;
  },

  // Удаление юзера из компании
  async deleteUserFromCompany(formData) {
    const { data } = await axiosInstance.delete(`/users/${formData.id}/companies`, {
      data: {
        companies: formData.companies
      }
    });
    return data;
  },

  // Получение списка компаний, доступных для добавления пользователя GET
  async getListUsersforAdd(formData) {
    const { data } = await axiosInstance.get(`/users/${formData.id}/addable_companies`, {
      params: {
        search: formData.searchValue
      }
    });
    return data;
  },

  // Получение списка пользователей доступных для добавления в компанию
  async getListAddableUsers(formData) {
    const { data } = await axiosInstance.get(`/company/${formData.id}/addable_users`, {
      params: {
        search: formData.searchValue,
        count: formData.count,
        offset: formData.offset
      }
    });
    return data;
  },
  // Импортирование геозон из KML, добавление файлов в компанию
  async postFilesToCompanies(file, companies_ids) {
    const formData = new FormData();

    if (file) {
      formData.append('file', file);
    }

    if (Array.isArray(companies_ids)) {
      formData.append('companies_ids', companies_ids);
    }

    const { data } = await axiosInstance.post(`/loadwln/geozones/create`, formData);

    return data;
  },

  // Удаление юзера из компании
  async toggleBlockedCompany(formData) {
    const { data } = await axiosInstance.post(`/company/block`, formData);

    return data;
  },
};
