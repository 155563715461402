<template>
  <div
    :class="['select', { disabled: disabled }]"
    tabindex="1"
    @blur="closeSelect"
  >
    <div
      class="select-header"
      :style="selectStyle"
      :class="{ select__error: error, 'select-header__open': close }"
      @click="openSelect"
    >
      <div class="select-header__row">
        <div
          v-if="label"
          :class="['select-header__label', { 'select__error-label': error }]"
        >
          {{ label }}
        </div>
        <div :class="['select-header__text', { 'select__error-placeholder': error, placeholder: !(modelValue && modelValue[truckBy]) }]">
          {{ modelValue ? checkEmptyValue(modelValue[truckBy]) : placeholder || $t("fill_field") }}
        </div>
      </div>
      <div class="flex items-center">
        <UiIcon
          value="arrow"
          width="5px"
          height="8px"
          :color="iconColor"
          class="select-img ml-1"
          :class="{ 'select-active__img': close }"
        />
      </div>
    </div>
    <transition
      name="error"
      appear
    >
      <div
        v-if="error"
        :class="['select__error-text']"
      >
        {{ errorText }}
      </div>
    </transition>

    <transition
      name="slide-fade"
      appear
    >
      <div style="position: relative;">
        <div
            v-if="close"
            :class="['select-options', { 'select-options__top': top }]"
        >
          <div
              v-if="search"
              @click="clearTime"
              class="select-options__search"
          >
            <UiInput
                v-model="searchValue"
                :height="heightSearch || height || '30px'"
                :placeholder="placeholder"
                search
                @input="clearTime"
                @blur="closeSelect"
            />

            <div class="select-options_items">
              <div
                  v-for="(item, key) in companies"
                  :key="key"
                  :class="['select-options__item', activeSelect(item)]"
                  @click="selectValue(item)">{{ item[truckBy] }}
              </div>
            </div>
          </div>

          <div
              v-else
              class="select-options_items"
          >
            <p v-if="!options.length">
              {{ $t("no_data") }}
            </p>
            <div
                v-else v-for="(item, key) in options"
                :key="key"
                :class="['select-options__item', activeSelect(item)]"
                @click="selectValue(item)">{{ item[truckBy] }}
            </div>
          </div>

          <div style="clear: both;"></div>
        </div>
      </div>


    </transition>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps, defineEmits, ref, onUnmounted } from 'vue';

import UiIcon from '@/components/ui/icon.vue';
import UiInput from '@/components/ui/input.vue';

const close = ref(false);
const searchValue = ref('');
const timeOut = ref(null);

const props = defineProps({
  modelValue: {
    type: [String, Object],
    default: () => {}
  },
  options: {
    type: Array,
    default: () => []
  },
  error: {
    type: Boolean,
    default: false
  },
  search: {
    type: Boolean,
    default: false
  },
  isInfo: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  errorText: {
    type: String,
    default: ''
  },
  truckBy: {
    type: String,
    default: 'text'
  },
  iconColor: {
    type: String,
    default: '#292929'
  },
  placeholder: {
    type: String,
    default: ''
  },
  height: {
    type: String,
    default: ''
  },
  heightSearch: {
    type: String,
    default: ''
  },
  width: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  },
  top: {
    type: Boolean,
    default: false
  }
});
const emit = defineEmits(['update:modelValue', 'change', 'close']);

const selectStyle = computed(() => {
  return {
    height: props.height,
    width: props.width
  };
});

const toggleScroll = (enable) => {
  const importingItems = document.querySelector('.importing__items');
  // const dropdownBody = document.querySelector('.dropdown__body');
  if (importingItems) {
    // importingItems.style.overflowY = enable ? 'auto' : 'hidden';
    // dropdownBody.style.overflowY = enable ? 'auto' : 'hidden';
  }
};

const activeSelect = computed(() => {
  return (item) => {
    return { active: props.modelValue && (item.id ? item.id === props.modelValue.id : item[props.truckBy] === props.modelValue[props.truckBy]) };
  };
});

const companies = computed(() => {
  const items = props.options;
  return items.filter((val) => {
    return val[props.truckBy].toLowerCase().includes(searchValue.value.toLowerCase());
  });
});

const openSelect = () => {
  if (!props.disabled) {
    close.value = !close.value;
    toggleScroll(!close.value); // Отключить скролл при открытии dropdown
  }
};

const closeSelect = () => {
  timeOut.value = setTimeout(() => {
    close.value = false;
    emit('close', close.value);
    toggleScroll(true); // Включить скролл при закрытии dropdown
  }, 250);
};

const clearTime = () => {
  if (timeOut.value) {
    clearTimeout(timeOut.value);
  }
};

const checkEmptyValue = (val) => {
  if (val) {
    return val
  } else {
    return props.placeholder;
  }
}

onUnmounted(() => {
  toggleScroll(true); // Включить скролл, когда компонент уничтожается
});

const selectValue = (item: object) => {
  emit('update:modelValue', item);
  emit('change', item);
  searchValue.value = '';
  close.value = !close.value;
};
</script>

<style scoped lang="scss">
.select {
  position: relative;
  outline: none;
  transition: 0.3s;

  &.disabled {
    opacity: 0.6;
  }

  &__error {
    border-color: red !important;

    &-label {
      color: #c93131 !important;
    }

    &-placeholder {
      color: #c93131 !important;
    }

    &-text {
      position: absolute;
      bottom: -15px;
      left: 12px;
      margin-top: 3px;
      font-size: 12px;
      color: #c93131;
      transition: 0.3s;
      font-weight: 400;
      line-height: 140%;
    }
  }

  &-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    padding: 3px 15px 8px 11px;
    width: 100%;
    background: #edeff3;
    border-radius: 12px;
    column-gap: 5px;
    box-sizing: border-box;

    &__row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &__label {
      font-weight: 700;
      font-size: 13px;
      line-height: 19px;
      color: #292929;
    }

    &__text {
      &.placeholder {
        color: #828d9b;
      }

      overflow: hidden;
      max-width: 250px;
      font-size: 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #292929;
      font-weight: 400;
      line-height: 19px;
    }
  }

  &-img {
    transition: 0.3s;
    transform: rotate(90deg);
  }

  &-options::-webkit-scrollbar {
    display: none;
  }

  &-options {
    scrollbar-width: none;
  }

  &-options {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1111;
    padding: 10px 0;
    margin-top: 4px;
    background: #edeff3;
    border: 1px solid #dce1e7;
    border-radius: 12px;
    box-shadow: 0 2px 2px rgba(255, 255, 255, 0.1), 0 4px 16px rgba(0, 0, 0, 0.06);
    transition: 0.3s;
    box-sizing: border-box;



    &_items {
      display: flex;
      overflow: hidden;
      overflow-y: auto;
      min-width: 150px;
      max-height: 200px;
      flex-direction: column;

      p {
        font-size: 14px;
        text-align: center;
        white-space: nowrap;
        color: #292929;
        font-weight: 500;
        line-height: 19px;
      }
    }

    &__search {
      overflow-y: auto;
      width: 100%;
    }

    :deep(.input) {
      margin: 0 10px 5px;
      width: calc(100% - 20px);
      background: #fff;
      border: 1px solid #dce1e7;
      border-radius: 6px;
    }

    :deep(.input__inner input) {
      background: #fff;
    }

    &__top {
      top: auto;
      bottom: 100%;
      margin-bottom: 5px;
      border-radius: 8px;
    }

    &__item {
      display: flex;
      align-items: center;
      padding: 5px 10px;
      width: 100%;
      font-size: 14px;
      color: black;
      transition: 0.3s;
      font-weight: 400;
      line-height: 20px;
      cursor: pointer;

      &:hover {
        padding: 5px 10px;
        font-size: 13px;
        color: #3a7cca;
        background: #f6f7fa;
        font-weight: 600;
      }

      &.active {
        font-weight: 600;
        color: #3a7cca;
      }
    }
  }

  &-active__img {
    transform: rotate(-90deg);
  }
}

.error-enter,
.error-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
