import axiosInstance from '../utils/axios.js';

export const Terminals = {
  async postAllTerminals({
                           from,
                           count,
                           companies,
                           value,
                           dealer,
                           field,
                           fields,
                           conditions,
                           forceCall,
                           sortField,
                           sortDesc
                         }) {
    const formData = {
      model: 'terminals',
      companies: companies || [],
      from: from || 0,
      count: forceCall ? null : count || 100,
      value: value || '',
      conditions: conditions || [],
      field,
      fields
    };
    if (dealer) {
      formData.dealer = dealer;
    }
    if (sortDesc !== '') {
      formData.sortDesc = sortDesc;
      formData.sortField = sortField;
    }
    const { data } = await axiosInstance.post('/admin_query', formData);

    return data;
  },
  async postAllCommands({ from, count, conditions }) {
    const { data } = await axiosInstance.post('/admin_query', {
      model: 'commands',
      conditions,
      from,
      count
    });

    return data;
  },

  async addCommands(form) {
    const { data } = await axiosInstance.post('/commands', {
      ...form
    });

    return data;
  },

  async createTerminals(form) {
    const { data } = await axiosInstance.post('/terminals_bulk', {
      ...form
    });

    return data;
  },

  async updateTerminals(params) {
    const { data } = await axiosInstance.put(`/terminals/${params.id}`, params);
    return data;
  },

  async getTerminalId(id) {
    const { data } = await axiosInstance.get(`/terminals?ids=${id}`);

    return data;
  },

  async deleteTerminals(ids) {
    const { data } = await axiosInstance.delete(`/terminals/${ids}`);

    return data;
  },

  async getAllTerminals() {
    const { data } = await axiosInstance.post('/terminals/list');

    return data;
  },

  async checkImei(formData) {
    const { data } = await axiosInstance.post('/terminals', formData);

    return data;
  },

  async getParameters(terminalType, imei) {
    try {
      const { data } = await axiosInstance.get(`/terminals/columns/${terminalType}/${imei}`);
      return data;
    } catch (error) {
      return error;
    }
  },

  async addParameters(formData) {
    try {
      const { data } = await axiosInstance.post(`/terminals/columns`, formData);
      return data;
    } catch (error) {
      return error;
    }
  },

  async getHistoryBlocked(id) {
    try {
      const { data } = await axiosInstance.post(`/terminals/${id}/block_unblock_logs`);

      return data;
    } catch (error) {
      return error;
    }
  }
};
