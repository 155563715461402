import axiosInstance from '../utils/axios.js';

export const CommandsTemplates = {
  async filterCommandsTemplates(form) {
    const { data } = await axiosInstance.post('/admin_query', form);
    return data;
  },
  async postAllCommandsTemplates({ from, count, field, fields, value, dealer, companies, sortField, sortDesc }) {
    const formData = {
      model: 'commands_templates',
      companies: companies || [],
      from,
      count,
      value: value || '',
      field,
      fields
    };
    if (dealer) {
      formData.dealer = dealer;
    }
    if (sortDesc !== '') {
      formData.sortDesc = sortDesc;
      formData.sortField = sortField;
    }
    const { data } = await axiosInstance.post('/admin_query', formData);

    return data;
  },
  async createCommandTemplate(form) {
    const { data } = await axiosInstance.post('/commands_templates', {
      ...form
    });

    return data;
  },

  async updateCommandsTemplates(params) {
    const { data } = await axiosInstance.put(`/commands_templates/${params.id}`, params);

    return data;
  },

  async deleteCommandsTemplates(id) {
    const { data } = await axiosInstance.delete(`/commands_templates/${id}`);

    return data;
  },

  async getDictionaries() {
    const { data } = await axiosInstance.get('/dictionaries');

    return data;
  },

  async getCommandTemplate(id) {
    const { data } = await axiosInstance.get(`/commands_templates?ids=${id}`);

    return data;
  }
};
