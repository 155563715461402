import ruRU from 'element-plus/dist/locale/ru.mjs';
import enEN from 'element-plus/dist/locale/en.mjs';
import kzKZ from 'element-plus/dist/locale/kk.mjs';

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

export function setElementLocale(language) {
  const locales = { en: enEN, ru: ruRU, kz: kzKZ };
  const locale = locales[language] || ruRU;
  return locale;
}
