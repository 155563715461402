import { createRouter as create, createWebHistory } from 'vue-router';

export const createRouter = (routes) =>
  create({
    history: createWebHistory(import.meta.env.BASE_UR),
    scrollBehavior() {
      return {
        el: 'body',
        top: 0
      };
    },
    routes
  });

