const module = {
  routes: [
    {
      path: '/object',
      component: () => import('@/modules/object/pages/index.vue'),
      meta: {
        layout: 'default',
        requiresAuth: true
      }
    },
    {
      path: '/object/journal/:id',
      component: () => import('@/modules/object/pages/journal.vue'),
      meta: {
        layout: 'default',
        requiresAuth: true
      }
    },
    {
      path: '/object/messages/:id',
      component: () => import('@/modules/object/pages/messages.vue'),
      meta: {
        layout: 'default',
        requiresAuth: true
      }
    }
  ]
};

export default module;
