import { v4 as uuidv4 } from 'uuid';

let WS;
let wsUserId;

export const initWS = () => {
  wsUserId = uuidv4();

  WS = new WebSocket(
    `${location.protocol === 'https:' ? 'wss' : 'ws'}://${
      location.host
    }/messages?id=${wsUserId}`
  );
  return WS;
};

export const getWS = () => {
  return WS;
};

export const getWSUserId = () => {
  return wsUserId;
};

export const closeWS = () => {
  WS.close();
};
