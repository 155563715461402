export const getToken = (token) => {
  return localStorage.getItem(token);
};

export const setToken = (token, value) => {
  localStorage.setItem(token, value);
};

export const unsetToken = (token) => {
  localStorage.removeItem(token);
};

export default { getToken, setToken, unsetToken };
