export const getTimezone = () => {
  const timeZoneOffset = new Date().getTimezoneOffset();
  // Convert the offset to hours and minutes
  const hoursOffset = Math.abs(Math.floor(timeZoneOffset / 60));
  const sign = timeZoneOffset >= 0 ? '-' : '+';

  // Construct the time zone string in the UTC±N format
  const timeZoneString = `UTC${sign}${hoursOffset}`;

  return timeZoneString;
};

export const allTimezones = [
  { value: "UTC-12", label: "UTC-12" },
  { value: "UTC-11", label: "UTC-11" },
  { value: "UTC-10", label: "UTC-10" },
  { value: "UTC-9", label: "UTC-9" },
  { value: "UTC-8", label: "UTC-8" },
  { value: "UTC-7", label: "UTC-7" },
  { value: "UTC-6", label: "UTC-6" },
  { value: "UTC-5", label: "UTC-5" },
  { value: "UTC-4", label: "UTC-4" },
  { value: "UTC-3", label: "UTC-3" },
  { value: "UTC-2", label: "UTC-2" },
  { value: "UTC-1", label: "UTC-1" },
  { value: "UTC+0", label: "UTC+0" },
  { value: "UTC+1", label: "UTC+1" },
  { value: "UTC+2", label: "UTC+2" },
  { value: "UTC+3", label: "UTC+3" },
  { value: "UTC+4", label: "UTC+4" },
  { value: "UTC+5", label: "UTC+5" },
  { value: "UTC+6", label: "UTC+6" },
  { value: "UTC+7", label: "UTC+7" },
  { value: "UTC+8", label: "UTC+8" },
  { value: "UTC+9", label: "UTC+9" },
  { value: "UTC+10", label: "UTC+10" },
  { value: "UTC+11", label: "UTC+11" },
  { value: "UTC+12", label: "UTC+12" },
  { value: "UTC+13", label: "UTC+13" },
  { value: "UTC+14", label: "UTC+14" }
]
