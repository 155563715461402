import { RouterView } from 'vue-router';

const module = {
  routes: [
    {
      path: '/dashboard',
      component: () => import('@/modules/auth/pages/Auth.vue'),
      children: []
    }
  ]
};

export default module;
