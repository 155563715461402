import { getWS, initWS } from './ws.js';
import { ref } from 'vue';
import { userStore } from '@/store/modules/user.js';
import { objectStore } from '@/store/modules/object.js';
import { useToast } from 'vue-toast-notification';
import { companyStore } from '@/store/modules/company.js';
import { rebroadcastsStore } from '@/store/modules/rebroadcasts.js';
import { integratorsStore } from '@/store/modules/integrators.js';
import { terminalsStore } from '@/store/modules/terminals.js';
import { commandsTemplatesStore } from '@/store/modules/commandsТemplates.js';

export const useWebsocketHandler = () => {
  const ws = ref(null);
  const wsInterval = ref(null);

  const $toast = useToast();

  const unref = (variable) => variable.value;
  const setref = (variable, value) => (variable.value = value);
  const showSuccessMsg = (msg) =>
    $toast.success(msg, {
      position: 'top-right'
    });

  const startWebSocket = () => {
    if (unref(ws)) {
      unref(ws).onclose = () => {
      };
      unref(ws).close();

      setref(ws, null);
    }

    initWS();

    setref(ws, getWS());

    unref(ws).onmessage = (event) => {
      const websocketData = JSON.parse(event.data);

      if (websocketData.notify) {
        const { objects, operation, id, msg, user_id } = websocketData.notify;
        const payload = {
          id,
          operation
        };

        const userS = userStore();
        if (userS.me.id === user_id) return;
        switch (objects) {
          case 'users':
            userS.WEBSOCKET_CHANGE(payload);

            showSuccessMsg(msg);
            break;
          case 'units':
            const objectS = objectStore();
            objectS.WEBSOCKET_CHANGE(payload);

            console.log(userS.me.id === user_id, 'MSG', msg);

            showSuccessMsg(msg);
            break;
          case 'company':
            const companyS = companyStore();
            companyS.WEBSOCKET_CHANGE(payload);

            showSuccessMsg(msg);
            break;
          case 'retranslators':
            const rebroadcastsS = rebroadcastsStore();
            rebroadcastsS.WEBSOCKET_CHANGE(payload);

            showSuccessMsg(msg);
            break;
          case 'dealers':
            const integratorsS = integratorsStore();
            integratorsS.WEBSOCKET_CHANGE(payload);

            showSuccessMsg(msg);
            break;
          case 'terminals':
            const termnalsS = terminalsStore();
            termnalsS.WEBSOCKET_CHANGE(payload);

            showSuccessMsg(msg);
            break;
          case 'commands_templates':
            const commandsTemplatesS = commandsTemplatesStore();
            commandsTemplatesS.WEBSOCKET_CHANGE(payload);

            showSuccessMsg(msg);
            break;

          case 'bill_info':
            console.log(payload, 'payload');

            showSuccessMsg(msg);
            break;
        }
      }
    };

    unref(ws).onopen = () => {
      clearInterval(unref(wsInterval));
      setref(wsInterval, null);
    };

    unref(ws).onclose = () => {
      // eslint-disable-next-line no-console
      setref(ws, null);
      clearInterval(unref(wsInterval));
      setref(wsInterval, null);

      setTimeout(() => {
        reconnectInterval();
      }, 35000);
    };

    unref(ws).onerror = (error) => {
      if (error.type === 'error') {
        clearInterval(unref(wsInterval));
        setref(wsInterval, null);

        setTimeout(() => {
          reconnectInterval();
        }, 35000);
        // TODO
        // this.$showError(this.$t('error.429'));
      }
    };
  };

  const check = () => {
    if (!unref(ws) || unref(ws).readyState === 3) {
      clearInterval(unref(wsInterval));
      setref(wsInterval, null);

      startWebSocket();
    } else if (unref(ws).readyState === 1) {
      clearInterval(unref(wsInterval));
      setref(wsInterval, null);
    }
  };

  const reconnectInterval = () => {
    if (unref(wsInterval)) {
      clearInterval(unref(wsInterval));
      setref(wsInterval, null);
    }

    const newInterval = setInterval(() => {
      check();
    }, 35000);

    setref(wsInterval, newInterval);
  };

  return {
    start: startWebSocket,
    check: check,
    reconnect: reconnectInterval
  };
};
