import { first } from 'lodash';
import { AxiosError } from 'axios';

export const isAxiosError = (err) =>
  !!(err instanceof AxiosError && err.response);

export const hasErrorResponse = (err) =>
  !!(isAxiosError(err) && err.response);

export class NetworkError extends Error {
  //
}

export class TooManyAttemptsError extends Error {
  //
}

export class ForbiddenError extends Error {
  //
}

export class UnauthorizedError extends Error {
  //
}

export class BadRequestError extends Error {
  //
}

export class ValidationError extends Error {
  constructor(
    err
  ) {
    super(err.response?.statusText);
  }

  hasError(key) {
    if (!hasErrorResponse(this.err)) {
      return false;
    }

    const { data } = this.err.response;

    return data.errors && !!data.errors[key];
  }

  getError(key) {
    if (!hasErrorResponse(this.err)) {
      return null;
    }

    const { data } = this.err.response;

    if (!data.errors) {
      return null;
    }

    return first(data.errors[key]) ?? null;
  }

  getErrors() {
    if (!hasErrorResponse(this.err)) {
      return {};
    }
    const { data } = this.err.response;

    if (!data.errors) {
      return {};
    }

    return data.errors;
  }
}