import { defineStore } from 'pinia';
import { Rebroadcasts } from '@/api/rebroadcasts.js';

export const rebroadcastsStore = defineStore('storeRebroadcasts', {
  state: () => ({
    allRebroadcastsMax: 0,
    allRebroadcasts: [],
    allRebroadcastsFiltered: [],
    filter: {
      integratorFilterModel: '',
      companyFilterModel: [],
      companyStatus: null
    },
    count: 0,
    sortField: '',
    sortDesc: ''
  }),

  actions: {
    async postAllRebroadcasts({ from = 0, count = 100, value, field, fields, sortField, sortDesc, conditions = null}, pagination) {
      try {
        if (!this.allRebroadcasts.length || pagination) {
          const formData = { from, count, value, field, fields, conditions };

          if (this.filter.integratorFilterModel) {
            formData.dealer = { id: this.filter.integratorFilterModel };
          }
          if (this.filter.companyFilterModel.length) {
            formData.companies = this.filter.companyFilterModel.map((comp) => ({ id: comp }));
          }
          if (this.sortDesc !== '') {
            formData.sortDesc = this.sortDesc;
            formData.sortField = this.sortField;
          }

          const response = await Rebroadcasts.postAllRebroadcasts(formData);

          this.allRebroadcasts = response.list;
          this.allRebroadcastsFiltered = response.list;
          this.allRebroadcastsMax = response.max;

          return response;
        }
      } catch (e) {
        throw new Error(e);
      }
    },

    async deleteRebroadcasts(ids) {
      try {
        const response = await Rebroadcasts.deleteRebroadcasts(ids);
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },

    async updateRebroadcasts(data) {
      try {
        const response = await Rebroadcasts.updateRebroadcasts(data);
        // this.allRebroadcastsFiltered.map((val, key) => {
        //   if (response.id === val.id) {
        //     response.company_name = data.company_name;
        //     this.allRebroadcastsFiltered.splice(key, 1, response);
        //   }
        // });
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },

    async createRebroadcast(data) {
      try {
        const response = await Rebroadcasts.createRebroadcast(data);
        response.company_name = data.company_name;
        this.allRebroadcastsFiltered.push(response);
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },

    async filterRebroadcasts(data) {
      try {
        const response = await this.postAllRebroadcasts({
          from: 0,
          count: 100,
          value: data.value,
          field: data.valueFilter?.value || ''
        }, true);
        return response;
      } catch (e) {
        throw new Error(e);
      }
    },

    async clearRebroadcastsFilter() {
      this.filter.integratorFilterModel = '';
      this.filter.companyFilterModel = [];
      const clearData = {
        companies: [],
        count: 100,
        field: '',
        fields: [],
        from: 0,
        model: 'retranslators',
        value: '',
        conditions: []
      };
      try {
        await this.postAllRebroadcasts(clearData, true);
      } catch (error) {
        console.error('Error during clearing rebroadcasts:', error);
      }
    },

    async WEBSOCKET_CHANGE({ id, operation }) {
      if (operation === 'DELETE') return this.DELETE_REBROADCAST(id);

      const data = await Rebroadcasts.getRebroadcast(id);

      operation === 'POST' ? this.ADD_REBROADCAST(data) : this.UPDATE_REBROADCAST(data);
    },

    ADD_REBROADCAST(rebroadcast) {
      if (!this.allRebroadcastsFiltered.some((x) => x.id === rebroadcast.id)) {
        this.allRebroadcasts = this.allRebroadcastsFiltered = [...this.allRebroadcastsFiltered, rebroadcast];
      }
    },

    UPDATE_REBROADCAST(rebroadcast) {
      const idx = this.allRebroadcastsFiltered.findIndex((elem) => elem.id === rebroadcast.id);

      if (idx === -1) return;

      this.allRebroadcasts = this.allRebroadcastsFiltered = [...this.allRebroadcastsFiltered.slice(0, idx), rebroadcast, ...this.allRebroadcastsFiltered.slice(idx + 1)];
    },

    DELETE_REBROADCAST(id) {
      this.allRebroadcasts = this.allRebroadcastsFiltered = this.allRebroadcastsFiltered.filter((item) => item.id !== id);
    },
    updateSortedRebroadcasts(payload) {
      this.allRebroadcastsFiltered = payload.list;
    },
    CLEAR_REBROADCAST_FILTER() {
      console.log('CLEAR_REBROADCAST_FILTER');
      this.filter.integratorFilterModel = '';
      this.filter.companyFilterModel = [];
      this.filter.companyStatus = null;
    }
  },

  getters: {
    getRebroadcasts: (state) => state.allRebroadcastsFiltered
  }
});
