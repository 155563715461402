import axiosInstance from '../utils/axios.js';

export const Integrators = {
  async postIntegratorDealer({ from, count, id, value }) {
    const { data } = await axiosInstance.post(`/auth_admin_query`, {
      from: +from,
      count: +count,
      dealer: { id: id },
      model: 'companies',
      value: value || ''
    });

    return data;
  },

  async postIntegratorInfo({ from, count, value, sortField, sortDesc }) {
    const formData = {
      from: +from,
      count: +count,
      value: value || ''
    };
    if (sortDesc !== '') {
      formData.sortDesc = sortDesc;
      formData.sortField = sortField;
    }
    const { data } = await axiosInstance.post('dealers_admin_query', formData);
    return data;
  },
  async sideFilterIntegrators(formData) {
    const { data } = await axiosInstance.post('/dealers_admin_query', formData);
    return data;
  },
  async deleteIntegrator(id) {
    const { data } = await axiosInstance.delete(`/dealers/${id}`);

    return data;
  },

  async createIntegrators(formData) {
    const { data } = await axiosInstance.post('/dealers', {
      ...formData
    });

    return data;
  },

  async getIntegrator(id) {
    const { data } = await axiosInstance.get(`/dealers?ids=${id}`);

    return data;
  },

  async editIntegrators(params) {
    const { data } = await axiosInstance.put(`/dealers/${params.id}`, params);

    return data;
  },

  async addFile(id, file) {
    return await axiosInstance.post(`/dealers/${id}/public_offer_files`, file);
  },

  async updateIntegrators(formData) {
    try {
      const { data } = await axiosInstance.put('/dealers/' + formData.id, formData);
      return data;
    } catch (error) {
      return error;
    }
  }
};
