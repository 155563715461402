import axiosInstance from '../utils/axios.js';

export const Search = {
  async allResult({ url, value, from, count, companies, model, to, first_row, max_rows, search }) {
    const params = {
      value: value || '',
      model: model || '',
      companies: companies || null,
      count: count || 100,
      from: from || 0

    };
    if (first_row || max_rows) {
      params.max_rows = 100;
      params.first_row = params.from;
      delete params.count;
      delete params.from;
    }
    if (to) {
      params.to = to;
      delete params.count;

    }
    if (search) {
      params.search = search;
      delete params.value;
    }
    if (model === 'users') {
      delete params.companies;
      params.fields = ['id', 'name', 'email', 'companies'];
    }

    const { data } = await axiosInstance.post(`/${url}`, {
      ...params
    });

    return data;
  },
  async deleteResult(ids) {
    const { data } = await axiosInstance.delete('/units', {
      data: {
        ids
      }
    });

    return data;
  },
  async deleteUnitsgroup(id) {
    try{
      const { data } = await axiosInstance.delete(`/unitsgroup/${id}`,);
      return data;
    }catch (e) {
      console.warn(e)
    }
  },
  async deleteNotification(id) {
    const { data } = await axiosInstance.delete(`/notifications/${id}`);

    return data;
  },
  async deleteMailings(id) {
    const { data } = await axiosInstance.delete(`/mailings/${id}`);

    return data;
  },

  async deleteReportTemplates(ids) {
    const { data } = await axiosInstance.delete(`/routes`, {
      data: {
        ids: [ids]
      }
    });

    return data;
  },
  async deleteRoute(id) {
    const { data } = await axiosInstance.delete(`/routes/${id}`);

    return data;
  },
  async deleteGeozones(id) {
    const { data } = await axiosInstance.delete(`/geozones/${id}`);

    return data;
  },

  async deleteTrailers(id) {
    const { data } = await axiosInstance.delete(`/trailers/${id}`);

    return data;
  },

  async deleteТrip(id) {
    const { data } = await axiosInstance.delete(`/trips/${id}`);

    return data;
  }
};
