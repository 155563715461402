import { createRouter } from '@/plugins/vue-router';
import JwtService from '@/common/jwt.service';

export const bootstrap = (app, modules) => {
  let routes = [];
  for (let i = 0; i < modules.length; i++) {
    const module = modules[i];
    if (module.routes) {
      routes.push(...module.routes);
    }

    if (module.extend) {
      module.extend(app);
    }
  }
  routes = routes.map((route) => {
    if (route.path === '/') {
      route.redirect = '/global-search';
    }
    return route;
  });
  const router = createRouter(routes);

  router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (JwtService.getToken('auth')) {
        next();
      } else {
        next('/auth/login');
      }
    } else {
      next();
    }
  });

  router.onError((error, to) => {
    if (!to?.fullPath) {
      window.location.reload();
    } else {
      window.location = to.fullPath;
    }
  });

  app.use(router);

  return app;
};
