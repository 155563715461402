const module = {
  routes: [
    {
      path: '/company',
      component: () => import('@/modules/company/pages/index.vue'),
      meta: {
        layout: 'default',
        requiresAuth: true
      }
    },
    {
      path: '/financialReport',
      component: () => import('@/modules/company/pages/financialReport.vue'),
      meta: {
        layout: 'default',
        requiresAuth: true
      }
    }
  ]
};

export default module;
