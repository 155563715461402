<template>
  <div :class="['tooltip', classes]">
    <div class="tooltip__activator">
      <slot name="activator"></slot>
    </div>
    <div class="tooltip__body">
      <span class="tooltip__body-text">
        {{ text }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, computed } from 'vue';

const props = defineProps({
  text: {
    type: String,
    default: ''
  },
  background: {
    type: String,
    default: ''
  },
  color: {
    type: String,
    default: ''
  },
  top: {
    type: Boolean,
    default: false
  },
  bottom: {
    type: Boolean,
    default: false
  },
  right: {
    type: Boolean,
    default: false
  },
  left: {
    type: Boolean,
    default: false
  }
});

const classes = computed(() => {
  return props.top ? 'top' : props.bottom ? 'bottom' : props.right ? 'right' : props.left ? 'left' : '';
});
</script>

<style scoped lang="scss">
.tooltip {
  position: relative;
  width: 100%;

  &.right {
    .tooltip__body {
      top: 50%;
      left: 101%;
      margin-left: 4px;
      transform: translateY(-50%);
    }
  }

  &.left {
    .tooltip__body {
      top: 50%;
      right: 101%;
      margin-right: 4px;
      transform: translateY(-50%);
    }
  }

  &.bottom {
    .tooltip__body {
      top: 101%;
      left: 50%;
      margin-top: 4px;
      transform: translateX(50%);
    }
  }

  &.top {
    .tooltip__body {
      bottom: 101%;
      left: 50%;
      margin-bottom: 4px;
      transform: translateX(-50%);
    }
  }

  &__activator {
    cursor: pointer;
    width: 100%;

    &:hover ~ .tooltip__body {
      opacity: 1;
      visibility: visible;
    }
  }

  &__body {
    position: absolute;
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 12px;
    background: v-bind(background);
    border-radius: 12px;
    opacity: 0;
    visibility: hidden;
    box-shadow: 0 4px 6px rgba(7, 40, 82, 0.06);
    transition: 0.3s;
    box-sizing: border-box;

    &-text {
      width: 100%;
      font-size: 12px;
      color: v-bind(color);
      font-weight: 400;
      line-height: 14px;
    }
  }
}
</style>
