<template>
  <button class="button" :class="[classes, { disabled: disabled }]" :disabled="disabled">
    <img
      v-if="loading"
      class="loading"
      src="@/assets/svg/loading.svg"
      alt="Loading"
    >
    <slot v-else />
  </button>
</template>

<script setup lang="ts">
import { defineProps, computed } from 'vue';

const props = defineProps({
  primary: {
    type: Boolean,
    default: false
  },

  error: {
    type: Boolean,
    default: false
  },

  text: {
    type: Boolean,
    default: false
  },

  height: {
    type: String,
    default: '40px'
  },

  width: {
    type: String,
    default: 'max-content'
  },

  minWidth: {
    type: String,
    default: 'auto'
  },

  color: {
    type: String,
    default: '#C95726'
  },

  background: {
    type: String,
    default: '#FFE3D7'
  },

  disabled: {
    type: Boolean,
    default: false
  },
  grey: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  }
});

const classes = computed(() => {
  return props.primary ? 'primary' : props.error ? 'error' : props.text ? 'text' : props.grey ? 'grey' : '';
});
</script>

<style lang="scss" scoped>
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  width: v-bind(width);
  min-width: v-bind(minWidth);
  height: v-bind(height);
  font-size: 16px;
  white-space: nowrap;
  color: v-bind(color);
  background: #FFE3D7;
  background: v-bind(background);
  border-radius: 8px;
  outline: none;
  transition: 0.3s;
  column-gap: 10px;
  cursor: pointer;
  font-weight: 600;
  line-height: 19px;
  box-sizing: border-box;

  &.primary {
    color: #FFF;
    background: #3A7CCA;
  }

  &.grey {
    font-size: 12px;
    color: #292929;
    background: #EDEFF3;
    border-radius: 30px;
    font-weight: 600;
    line-height: 80%;
  }

  &.error {
    color: #FFF;
    background: #C93131;
  }

  &.text {
    padding: 0;
    color: v-bind(color);
    background: none;
    border-radius: 0;
  }

  &.disabled {
    opacity: 0.6;
    cursor: default;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading {
  animation: rotate 2s linear infinite;
}
</style>
