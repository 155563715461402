import { VueMaskDirective } from 'v-mask';

const vMaskV2 = VueMaskDirective;
export const vMaskV3 = {
  beforeMount: vMaskV2.bind,
  updated: vMaskV2.componentUpdated,
  unmounted: vMaskV2.unbind
};
export default vMaskV3;

